import {validate} from "validate.js"

export function findErrors(
    json_data,
    rules
): {isValid: boolean; errors: {[key: string]: string} | null} {
    let r = validate(json_data, rules)

    if (!r) {
        return {isValid: true, errors: {}}
    }
    let r2 = {...r}
    for (let key in r2) {
        r2[key] = r2[key][0]
    }
    console.log(r2)
    return {isValid: false, errors: r2}
}
