import React, {Component} from "react"
import Select, {Props} from "react-select"

interface KlassDropdownProps extends Props {
    onChange: any
    options: any
    value: any
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: "3px",
        backgroundColor: "#f6f6f6",
        minHeight: "30px",
        height: "30px",
        border: "solid 1px var(--black-150)",
        "&:hover": {border: "solid 1px var(--black-150)"},
        boxShadow: state.isFocused ? "0 0 0 2px var(--primary-400-base)" : 0
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        height: "28px",
        justifyContent: "center",
        color: "var(--black-base)",
        "&:hover": {color: "var(--black-base)"},
        cursor: "pointer"
    }),

    input: (provided, state) => ({
        ...provided,
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        lineHeight: "normal",
        color: "var(--black-base)"
    }),

    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: "14px"
    }),

    singleValue: (provided) => ({
        ...provided,
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        lineHeight: "normal",
        color: "var(--black-base)"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        lineHeight: "normal",
        color: "var(--black-200)"
    }),

    menuList: (provided) => ({
        ...provided,
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        lineHeight: "normal"
    })
}

export class KlassDropdown extends Component<KlassDropdownProps, {}> {
    public render() {
        const props = {...this.props, styles: customStyles}

        return <Select {...props} />
    }
}
