import {Model} from "./Model"

export class Bridge {
    constructor(private model: Model) {}

    public signOut() {
        console.log("PLATFORM - signOut")
        this.model.auth.signOut()
        this.model.visitor = null
    }

    public switchProfile(token: string) {
        this.model.auth.switchProfile(token)
    }
}
