import React, {Component} from "react"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {Button} from "pages/sign-in/parts/Button"
import {LoginWrapper} from "components/LoginWrapper"
import {Model} from "Model"
import {observable} from "mobx"
import {observer} from "mobx-react"
import {findErrors} from "../../lib/functions/findErrors"
import styles from "./AccountRecovery.module.css"

const rules = {
    email: {
        email: true
    }
}

interface Props {
    model: Model
}

@observer
export class AccountRecovery extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    // @observable
    // private backendErrors: string | null = ""

    @observable
    private user = {
        email: ""
    }

    private isValid = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        this.errors = errors
        return isValid
    }

    public render() {
        return (
            <LoginWrapper>
                <div className={styles.root}>
                    <h2 className={styles.h2}>Trouble Logging In?</h2>

                    <article className={styles.loginArea}>
                        <div className={styles.inner}>
                            <p className={styles.text}>
                                Enter your email address, and we`ll email instructions for setting a
                                new password.
                            </p>
                            <Label
                                text="Email address"
                                className={styles.inputUser}
                                error={this.errors["email"]}
                                required>
                                <Input
                                    onChange={(v) => {
                                        this.user.email = v
                                    }}
                                    value={this.user.email}
                                />
                            </Label>

                            <Button
                                text="Send Password Reset Email"
                                onClick={() => this.sendRecoveryEmailToUser()}
                                className={styles.button}
                            />

                            <Button
                                text="Back To Login"
                                onClick={() => this.props.model.getHistory().push("/sign-in")}
                                className={styles.button}
                            />
                        </div>
                    </article>
                </div>
            </LoginWrapper>
        )
    }

    private async sendRecoveryEmailToUser() {
        if (this.isValid()) {
            const {email} = this.user
            const response = await this.props.model.sendRecoveryEmail(email)
            if (response.success) {
                alert(`${response.message}`)
            }
        }
    }
}
