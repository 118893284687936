import {action, observable, runInAction} from "mobx"
import {Gate, GateResponse} from "../lib/gate/Gate"
import {SignUpUser} from "../pages/sign-up/SignUp"

export class Authorisation {
    @observable
    public authorisedByToken: boolean = false

    @observable
    public status: "loading" | "authorised" | "guest" = "loading"

    @observable
    public token: string | null = null

    constructor(private gate: Gate, private storage: Storage) {}

    @action
    public async refreshToken(): Promise<GateResponse> {
        let token = this.storage.getItem("token")
        this.token = token
        if (!token) {
            this.status = "guest"
            return {success: true}
        }
        this.gate.setToken(token)

        let r = await this.gate.request("/auth/refresh-token", {})

        this.onRefreshToken(r)

        return r
    }

    @action
    private onRefreshToken(r: GateResponse) {
        if (r.success) {
            this.authorisedByToken = true
            const token = r.data.token
            this.storage.setItem("token", token)
            this.token = token
            this.gate.setToken(token)
            this.status = "authorised"
        } else {
            this.status = "guest"
            if (r.errors) {
                if (r.errors[0].message === "invalid signature") {
                    alert("Can not refresh JWT token!")
                    this.signOut()
                }
            }
        }
    }

    public async signUp(slug: string, user: SignUpUser): Promise<GateResponse> {
        delete user.confirm_password
        let r = await this.gate.request("/auth/sign-up", {
            data: {...user, slug}
        })

        if (r.success) {
            // alert("User created. Sign In with your credentials")
            const userCreated = {
                email: user.email,
                password: user.password,
                captcha_value: user.captcha_value,
                skip: true
            }
            this.storage.removeItem("token")
            await this.signIn(slug, userCreated)
            window.location.reload()
        }

        return r
    }

    @action
    public async signIn(slug: string, user: any): Promise<GateResponse> {
        let r: GateResponse = await this.gate.request("/auth/sign-in", {
            data: {slug, ...user}
        })

        if (r.success) {
            runInAction(() => {
                const token = r.data.token
                this.storage.setItem("token", token)
                this.token = token
                this.gate.setToken(token)
                this.status = "authorised"
                window.location.reload()
            })
        }

        return r
    }

    @action
    public async signOut() {
        await this.gate.request("/cache/remove", {})
        this.storage.removeItem("token")
        this.token = null
        this.gate.setToken(null)
        this.authorisedByToken = false
        this.status = "guest"
    }

    @action
    public switchProfile(token: string) {
        this.storage.setItem("token", token)
        this.token = token
        this.gate.setToken(token)
        this.status = "authorised"
        // window.location.reload()
    }
}
