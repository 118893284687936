import React, {Component} from "react"
import styles from "./LoginWrapper.module.css"
import {Header} from "./parts/Header"

import {Footer} from "./parts/Footer"
import classNames from "classnames"

interface Props {
    children: JSX.Element
    className?: string
}

export class LoginWrapper extends Component<Props, {}> {
    public render() {
        const {children, className = ""} = this.props
        return (
            <div className={styles.root}>
                <Header />
                <div className={classNames(styles.wrapper, className)}>{children}</div>
                <Footer />
            </div>
        )
    }
}
