import React, {Component} from "react"
import classNames from "classnames"
import styles from "./Button.module.css"

interface Props {
    text: string
    className?: string
    disabled?: boolean
    onClick: () => void
}

export class Button extends Component<Props, {}> {
    public render() {
        const {text, className = "", disabled, onClick} = this.props
        return (
            <button
                className={classNames(className, styles.root)}
                type="button"
                disabled={disabled}
                onClick={onClick}>
                {text}
            </button>
        )
    }
}
