import React, {Component, KeyboardEvent} from "react"

import {Label} from "../inputs/Label"

import {observer} from "mobx-react"

import ReCaptcha from "react-google-recaptcha"
import styles from "./Captcha.module.css"

interface Props {
    id: string
    user: any
    errors?: {[key: string]: string}
    onChange: Function
    onKeyDown?: (event: KeyboardEvent<Element>) => void
    backendErrors?: string | null
    recaptchaRef: any
}

@observer
export class Captcha extends Component<Props> {
    componentDidMount() {
        // if (this.recaptchaRef) {
        //     this.recaptchaRef.reset()
        // }
    }

    public render() {
        const {id, errors, onChange, recaptchaRef} = this.props

        const error = errors[id]
        let css = styles.root
        if (error) {
            css = `${css} ${styles.rootError}`
        }

        return (
            <div className={styles.checkBoxWrapper}>
                <Label
                    text=""
                    className={styles.inputPass}
                    error={errors["captcha_value"]}
                    required>
                    <ReCaptcha
                        sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
                        size="normal"
                        render="explicit"
                        ref={recaptchaRef}
                        onChange={(value) => {
                            onChange("captcha_value", value)
                        }}
                        onExpired={() => {
                            recaptchaRef.current.reset()
                        }}
                    />
                </Label>
            </div>
        )
    }
}
