import React, {Component} from "react"
// import styles from "./Preloader.module.css"

interface Props {}

export class Preloader extends Component<Props, {}> {
    public render() {
        return <div>LOADING ...</div>
    }
}
