import React, {Component} from "react"
import styles from "./Footer.module.css"

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>American National University</p>
                    <p className={styles.text}>1813 East Main Street</p>
                    <p className={styles.text}>Salem, VA 24153</p>
                </div>
            </footer>
        )
    }
}
